/* App.css */
input:-webkit-autofill {
    -webkit-text-fill-color: white !important;
    -webkit-box-shadow: 0 0 0px calc(var(--unit) * 2) #222 inset !important; /* Change to your desired color */
    background-clip: content-box !important;
}

:root {
  --unit: 20px;
  --color-accent-primary: #ffa500;
  --color-accent-secondary: #ffd000;
  --color-sidebar-plus: #777;

  --patternbg-image: url('images/tile-diagonal.png');
  --patternbg-size: 30px;
  --patternbg-color: #222;

  --font-size-default: calc(var(--unit) * 0.8);
  --font-size-small: calc(var(--unit) * 0.625);
  --font-size-tiny: calc(var(--unit) * 0.5);
  --font-size-title: calc(var(--unit) * 1);
  --font-calendar: calc(var(--unit) * 0.75);
}

@media (max-width: 430px) {
    :root {
        --unit: 4.68vw;
        --font-calendar: calc(var(--unit) * 0.625);
    }
}

::-webkit-scrollbar-track {
  background: rgba(20, 20, 20, 1); /* Black background */
}

::-webkit-scrollbar-thumb {
  background-color: #555; /* Light grey handle */
}

html {
  scrollbar-color: #555 rgba(20, 20, 20, 1); /* Light grey handle and black background */
}

body {
  position: relative;
  background-color: rgba(20, 20, 20, 1);
  color: #ccc;
  text-align: center;
  font-size: var(--font-size-default);
}

select {
  background-color: rgba(0, 0, 0, 0);
  color: #ccc;
  border: none;
  border-color: #555;
  outline: none;
  font-size: var(--font-size-default);
  border-radius: calc(var(--unit) * 0.25);
  margin-inline: calc(var(--unit) * 0.5);
  font-weight: bold;
}

input {
  appearance: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  border-radius: calc(var(--unit) * 0.25);
  font-size: var(--font-size-default);
  height: calc(var(--unit) * 2);
  padding-inline: calc(var(--unit));
}

input, button {
  appearance: none;
  -webkit-appearance: none;
  border-radius: calc(var(--unit) * 2);
  font-size: var(--unit);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

button {
  background-color: var(--color-accent-primary);
  border: none;
  font-weight: bold;
  cursor: pointer;
  transition: background-color var(--animation-speed-standard) ease;
  color: black;
  transition: background-color 0.3s;
  height: calc(var(--unit) * 2);
  padding-inline: var(--unit);
  box-sizing: border-box;
}

select:hover {
  background: rgba(102, 102, 102, 0.25);
}

form {
  display: flex;
  flex-direction: column;
  gap: calc(var(--unit) * 0.5);
}

h2 {
  font-size: var(--font-size-small);
  color: #777;
  margin-top: var(--unit);
  padding-left: calc(var(--unit) * 0.5);
}

.page-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--unit);
}

.textlink {
  color: var(--color-accent-primary);
  cursor: pointer;
  transition: color 0.3s ease;
}

.textlink:hover {
  color: var(--color-accent-secondary);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #000;
  padding: var(--unit);
  border-radius: 5px;
  width: 300px;
  max-width: 80%;
}

.modal-content h2 {
  margin-top: 0;
}

.context-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: calc(var(--unit) * 0.25);
  background-color: rgba(51, 51, 51, 1);
  border: 1px solid #777;
  z-index: 1000;
  min-width: calc(var(--unit) * 10);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: calc(var(--unit) * 0.25);
  padding: calc(var(--unit) * 0.25);
}

.context-menu button {
  display: flex;
  padding-inline: calc(var(--unit) * 0.5);
  padding: calc(var(--unit) * 0.25);
  text-align: left;
  background: none;
  border: none;
  cursor: default;
  border-radius: 3px;
  justify-content: space-between;
}

.context-menu button:hover {
  background-color: #777;
}

.context-menu button:disabled {
  background-color: none;
  color: #aaa;
}

.context-menu button:disabled:hover {
  background: none;
}

.context-menu .active {
  background-color: #777;
}

.fullpage-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100dvw;
  height: 100dvh;
  font-size: calc(var(--unit) * 2);
}

.menu-separator {
  height: 1px;
  background-color: #777;
}

.menu-label {
  display: flex;
  flex-direction: row;
}

.checkable-space {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  min-width: calc(var(--unit)a);
  width: calc(var(--unit));
}

.text-regular {
  font-size: var(--font-size-default);
}

.time-signature-format {
  font-size: var(--font-size-tiny);
}

.select-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: calc(var(--unit) * 0.5);
  padding: calc(var(--unit) * 0.25);
  margin-inline: calc(var(--unit) * 0.25);
  font-size: var(--font-size-title);
  font-weight: bold;
  border-radius: calc(var(--unit) * 0.25);
  color: #ccc;
}

.select-view:hover {
  color:#fff
}

.dev-login-overlay {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.dev-login-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--unit);
  margin: auto;
  background-color: #333;;
  border-radius: var(--unit);
  padding: var(--unit);
}

.row {
  display: flex;
  flex-direction: row;
}

.organizerviewer {
  display: flex;
  height: 100dvh;
  width: 100dvw;
}

.Organizer {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  user-select: none;
}

.OrganizerNav {
  display: flex;
  background-color: #333;
  align-items: center;
}

.OrganizerContent {
  flex: 1;
  background-color: #222;
  overflow: hidden;
}

.Organizer.wide {
  flex-direction: row;
}

.Organizer.wide .OrganizerNav {
  width: calc(3 * var(--unit));
  flex-direction: column;
  padding-block: calc(0.5 * var(--unit));
}

.Organizer.narrow {
  flex-direction: column-reverse; /* Changed from column to column-reverse */
}

.Organizer.narrow .OrganizerNav {
  justify-content: space-evenly;
  height: calc(3 * var(--unit));
  flex-direction: row;
  width: 100%; /* Added to ensure full width in narrow view */
}

.ContentSplit {
  display: flex;
  width: 100%;
  height: 100%;
}

.ContentNav, .ContentDetails {
  flex: 1;
  overflow: auto;
  height: 100%;
}

.ContentResize {
  width: 6px;
  background-color: #444;
  cursor: col-resize;
}

.Organizer.wide .ContentSplit {
  flex-direction: row;
}

.Organizer.narrow .ContentSplit {
  flex-direction: column;
}

.Organizer.narrow .ContentResize {
  width: 100%;
  height: 5px;
  cursor: row-resize;
}

.DayView {
  width: 100%;
  height: 100%;
}

.OrganizerNav svg {
  cursor: pointer;
  color: white;
  font-size: 1.5rem;
  padding: 0.5rem;
}

.OrganizerNav svg:hover {
  color: #ddd;
}

.CalendarNav {
  height: 100%;
}

.HeaderDay {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(var(--unit) * 1.5);
  font-weight: bold;
  padding: calc(var(--unit) * 0.5);
  height: calc(var(--unit) * 3);
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #333;
  min-height: calc(var(--unit) * 2);
  border-radius: calc(var(--unit) * 0.5);
  transition: background-color 0.15s ease-out;
  padding: calc(var(--unit) * 0.5) calc(var(--unit) * 0.75);
  box-sizing: border-box;

}

.item .primary {

}

.item .secondary {
  font-size: var(--font-size-small);
  margin-left: calc(var(--unit) * 0.5);
  color: #777;
}

.item:hover {
  background-color: #555;
}

.item-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: calc(var(--unit) * 0.5);
  margin-bottom: calc(var(--unit) * 0.5);
  border-radius: calc(var(--unit) * 0.5);
  width: 100%;
}

.list-plus-button {
  font-size: var(--unit);
  background-color: none;
  border-radius: calc(var(--unit) * 0.5);
  width: 100%;
  min-height: calc(var(--unit) * 2);
  border-radius: calc(var(--unit) * 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: calc(var(--unit) * 0.75);
  margin-bottom: calc(var(--unit) * 1);
  transition: background-color 0.15s ease-out;
  box-sizing: border-box;
  cursor: pointer;
}

.list-plus-button:hover {
  background-color: #555;
}

.list-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.list-page .header-container {
  margin-bottom: calc(var(--unit) * 0.25);
}

.list-content {
  flex: 1;
  width: 100%;
  height: auto;
  max-width: calc(var(--unit) * 30);
  padding-inline: calc(var(--unit) * 3);
  box-sizing: border-box;
  overflow-y: auto;
}


.ItemList {
  width: 100%;
  height: 100%;
}

.swiper-tasks {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.swiper-tasks .swiper-slide {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  min-height: 100%;
}

/* Calendar */

.calendar-component {
display: flex;
flex-direction: column;
height: 100%;
background-color: #222;
color: #fff;
cursor: default;
}

.header-container {
  color: #fff;
}

.header {
display: flex;
justify-content: space-between;
align-items: center;
font-size: calc(var(--unit) * 1.5);
min-height: calc(var(--unit) * 3);
font-weight: bold;
}


.nav-month-caret {
  font-size: var(--unit);
  padding-inline: calc(var(--unit) * 1);

}

.header .header-selection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: calc(var(--unit) * 0.125) calc(var(--unit) * 0.5);
  border-radius: calc(var(--unit) * 0.25);
  box-sizing: border-box;
  gap: calc(var(--unit) * 0.25);
}

.header-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: calc(var(--unit) * 0.25);
}

.header .header-selection.selected {
  background-color: rgba(255, 255, 255, 0.125);
  border: 1px solid #fff;
}

.header-container .header .primary {
font-weight: bold;
}

.header-container .header .secondary {
font-weight: normal;
}

.weekDays {
display: grid;
grid-template-columns: repeat(7, 1fr);
gap: 1px;
width: 100%;
text-align: right;
min-height: calc(var(--unit) * 1.5);
}

.weekDays .day {
display: flex;
align-items: center;
justify-content: right;
padding: calc(var(--unit) * 0.125);
margin: calc(var(--unit) * 0.125);
min-width: var(--unit);
min-height: var(--unit);
font-size: var(--font-calendar);
}

.monthGrid {
display: grid;
grid-template-columns: repeat(7, 1fr); /* Ensure 7 columns for days */
gap: 1px;
height: 100%;
width: 100%;
}

.cell {
background-color: #111;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-size: var(--font-calendar);
box-sizing: border-box;
}

.cell.selected {
  background-color: rgba(255, 255, 255, 0.125);
  border: 1px solid #fff;
  opacity: 1;
}

.cell.outsideMonth {
background-color: #181818;
}

.cell.previousDay {
  opacity: 0.5;
}

.cell.validDay {
color: #fff;
}

.cell .cell-heading {
display: flex;
justify-content: space-between;
width: 100%;
}

.cell .calendar-cell-tallies,
.cell .calendar-cell-date-number {
display: flex;
align-items: center;
justify-content: right;
padding: calc(var(--unit) * 0.125);
margin: calc(var(--unit) * 0.125);
}

.cell .calendar-cell-date-number.current {
position: relative;
z-index: 2;
}

.cell .calendar-cell-date-number.current::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(var(--unit) * 1);
  height: calc(var(--unit) * 1);
  background-color: rgb(207, 31, 0);  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: -1; /* Ensure the circle is behind the content */
}

.cell .cell-content {
padding: 4px;
flex: 1;
}

.relative-parent {
  position: relative;
  min-width: calc(var(--unit) * 2);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.size-calibration {
  visibility: hidden;
  white-space: pre-wrap; /* Allows for line breaks in textarea */
  height: auto;
  word-wrap: break-word; /* Prevent overflow for long words */
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; /* Place it behind the textarea */
  pointer-events: none; /* Make it unselectable */
  font-size: var(--font-size-default);
  font-family: Arial, Helvetica, sans-serif;
  padding: 0;
  box-sizing: border-box;
}

.autosized-input {
  width: 100%;
  font-size: var(--font-size-default);
  outline: none;
  appearance: none;
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
  background: none;
  border: none;
  color: white;
  border-radius: 0;
  overflow: hidden; /* Hide scrollbar */
  padding: 0;
  box-sizing: border-box;
}

/* Central Operations */

.centralops {
  display: flex;
  flex-direction: row;
  height: 100dvh;
  width: 100dvw;
}

.centralnav {
  background-color: #000;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding-top: var(--unit);
  padding-inline: calc(var(--unit) * 0.5);
}

.centralcontent {
  overflow-y: auto;
  flex: 1;
}

.mobile-navselect {
  display: none;
}

.mobile-navselect select {
  font-size: calc(var(--unit) * 1);
  padding: calc(var(--unit) * 0.25);
}

.desktop-navlist {
  display: flex;
  flex-direction: column;
  visibility: visible;
  width: 100%;
  overflow-y: auto;
  padding-bottom: var(--unit);
  gap: calc(var(--unit) * 0.25);
}

.navlist-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: calc(var(--unit) * 0.5);
}

.navlist-logo {
  padding-inline: calc(var(--unit) * 0.5);
  margin-bottom: calc(var(--unit) * 0.5);
  min-width: 200px;
  box-sizing: border-box;
}

.navlist-hlogo {
  width: calc(var(--unit) * 2);
  padding-inline: calc(var(--unit) * 0.5);
  box-sizing: border-box;
}

.navlist-item.active {
  color: var(--color-accent-secondary);
  background-color: #333;
}

.navlist-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: calc(var(--unit) * 0.25) 0;
  cursor: pointer;
  border-radius: calc(var(--unit) * 0.25);
  box-sizing: border-box;
  width: 100%;
}

.navlist-item:hover {
  background-color: #555;
}

.navlist-link {
  display: flex;
  flex-direction: row;
}

.navlist-badge {
  font-size: var(--font-size-small);
  color: #777;
  border-radius: calc(var(--unit) * 0.5);
  min-width: calc(var(--unit) * 0.5);  /* Set the width */
  min-height: calc(var(--unit) * 1); /* Set the height to the same value */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: calc(var(--unit) * 0.25);
  padding-inline: calc(var(--unit) * 0.25);
}

.navlist-badge.important {
  background-color: red;
  color: #fff;
}

.navlist-icon {
  width: calc(var(--unit) * 2)
}

.navlist-title {
  font-weight: bold;
  padding-inline: calc(var(--unit) * 1.5);
}

.navlist-divider {
  background-color: #444;
  min-height: 1px;
  margin: calc(var(--unit) * 0.5) 0;
}

@media (max-width: 660px) {
    .centralops {
      flex-direction: column;
    }
    .centralnav {
      min-width: 100%;
      padding-bottom: calc(var(--unit) * 0.5);
      padding-top: calc(var(--unit) * 0.5);
    }
    .mobile-navlist {
      width: 100%;
      flex: 1;
    }
    .mobile-navselect {
      display: flex;
      flex-direction: column;
      align-items: start;
      flex: 1;
      font-size: var(--font-size-title);
      box-sizing: border-box;
    }
    .mobile-navselect .navlist-item.button {
      color: white;
      justify-content: space-between;
      width: 100%;
      padding: calc(var(--unit) * 0.5) calc(var(--unit) * 0.5) calc(var(--unit) * 0.5) 0;
      border: 1px solid #333;
      box-sizing: border-box;
    }
    .mobile-navselect.expanded .navlist-item {
      padding: calc(var(--unit) * 0.5) calc(var(--unit) * 0.5) calc(var(--unit) * 0.5) 0;
    }
    .mobile-navselect.expanded .navlist-item:hover {
      background: none;
    }
    .mobile-navselect .navlist-item.active {
      display: none;
    }
    .desktop-navlist {
      display: none;
    }
    .mobile-navselect.expanded {
      display: block;
      position: fixed;
      top: 0;
      bottom: 0;
      background-color: #000;
      left: 0;
      right: 0;
      justify-content: center;
      z-index: 10000;
      overflow-y: auto;
      padding: calc(var(--unit) * 0.5);
    }
    .navlist-badge {
      margin-right: 0;
    }
}

.temp-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.temp-outline {
  text-align: left;
}

.temp-outline ul {
  margin-top: calc(var(--unit) * 0.5);
}

.temp-outline li  {
  margin-bottom: calc(var(--unit) * 0.5);
}