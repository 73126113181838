.contact-dashboard {
  background-color: #1a1a1a;
  color: #ffffff;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  margin-bottom: 20px;
}

.dashboard-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.add-person-btn {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

.add-person-btn .icon {
  margin-right: 5px;
  vertical-align: middle;
}

.sort-controls {
  display: flex;
  align-items: center;
}

.sort-select,
.display-field-select {
  background-color: #333;
  color: #fff;
  padding: 10px;
  font-size: 16px;
  border: none;
  margin-right: 10px;
}

.sort-order-btn {
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
}

.contact-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.contact-table th {
  background-color: #333;
  color: #fff;
  padding: 12px;
  text-align: left;
}

.contact-table .even-row {
  background-color: #2a2a2a;
}

.contact-table .odd-row {
  background-color: #222;
}

.contact-table .even-row:hover {
  background-color: #555;
}

.contact-table .odd-row:hover {
  background-color: #555;
}

.contact-table td {
  padding: 12px;
}